import React from "react"

function AccessibilityDeclarationTemplate() {
  return (
    <div>
      <h1>Deklaracja dostępności</h1>
      <p>
        <span className="italicize">
          Zespół Szkolno- Przedszkolny im. Mikołaja Kopernika w Nidku
        </span>{" "}
        zobowiązuje się zapewnić dostępność swojej strony internetowej zgodnie z
        przepisami ustawy z dnia 4 kwietnia 2019 r. o dostępności cyfrowej stron
        internetowych i aplikacji mobilnych podmiotów publicznych. Oświadczenie
        w sprawie dostępności ma zastosowanie do strony internetowej{" "}
        <span className="italicize bold">zspnidek.pl</span>
      </p>
      <p className="italicize bold">
        Zespół Szkolno- Przedszkolny im. Mikołaja Kopernika w Nidku, Nidek ul.
        św. Judy Tadeusza 2, 34-122 Wieprz
      </p>
      <p className="italicize bold">
        tel. <a href="tel:338755811">338755811</a>
      </p>
      <p className="italicize bold">
        e-mail:{" "}
        <a href="mailto:sekretariat@zspnidek.wieprz.pl">
          sekretariat@zspnidek.wieprz.pl
        </a>
      </p>
      <h2 className="bold">Data publikacji i aktualizacji</h2>
      <p>Data publikacji strony internetowej: 1.08.2021</p>
      <p>Data ostatniej istotnej aktualizacji: 19.03.2025</p>
      <h2 className="bold">Status pod względem zgodności z ustawą</h2>
      <p>
        Ta strona internetowa jest w pełni zgodna z załącznikiem do ustawy o
        dostępności cyfrowej z dnia 4 kwietnia 2019 r. o dostępności cyfrowej
        stron internetowych i aplikacji mobilnych podmiotów publicznych.
      </p>
      <h2 className="bold">
        Data sporządzenia deklaracji i metoda oceny dostępności cyfrowej
      </h2>
      <p>Oświadczenie sporządzono dnia: 27 grudnia 2024 r.</p>
      <p>
        Deklarację sporządzono na podstawie samooceny przez{" "}
        <span className="italicize">podmiot publiczny</span>
      </p>
      <h2 className="bold">Informacje zwrotne i dane kontaktowe</h2>
      <p>
        W przypadku problemów z dostępnością strony internetowej prosimy o
        kontakt. <br /> Osobą kontaktową jest{" "}
        <span className="italicize">mgr Ewa Snażyk-Płonka</span>
      </p>
      <p>
        Kontaktować można się także dzwoniąc na numer telefonu{" "}
        <a href="tel:338755811">33 875 58 11</a>, kontakt mailowy{" "}
        <a href="mailto:sekretariat@zspnidek.wieprz.pl">
          sekretariat@zspnidek.wieprz.pl
        </a>{" "}
        lub{" "}
        <a href="mailto:dyrektor@zspnidek.wieprz.pl">
          dyrektor@zspnidek.wieprz.pl
        </a>{" "}
        Tą samą drogą można składać wnioski o udostępnienie informacji
        niedostępnej oraz składać skargi na brak zapewnienia dostępności.
      </p>
      <h2 className="bold">Korzystanie z klawiatury</h2>
      <p>
        <span className="bold">Tab</span>: przenosi fokus (punkt uwagi) do
        następnego aktywnego elementu na stronie (odnośnika, formularza){" "}
      </p>
      <p>
        <span className="bold">Shift + Tab</span>: przenosi fokus do
        poprzedniego aktywnego elementu na stronie (odnośnika, formularza){" "}
      </p>
      <p>
        <span className="bold">Enter</span>: uaktywnia odnośnik i przyciski.{" "}
      </p>
      <p>
        Świadomie zrezygnowaliśmy z możliwości szerszego zastosowania skrótów
        klawiaturowych, aby uniknąć konfliktów z technologiami asystującymi (np.
        czytnikami ekranów), systemem lub aplikacjami użytkowników.
      </p>
      <h2 className="bold">Udogodnienia</h2>
      <p>
        Serwis jest wyposażony w udogodnienia ułatwiające przeglądanie treści
        przez osoby niedowidzące:{" "}
      </p>
      <ul>
        <li>przełącznik zmiany rozmiaru czcionki, </li>
        <li>przełączniki zmiany kontrastu, </li>
        <li>
          na stronie zastosowano technologie "skip navigation" – szybkie
          przemieszczanie do wskazanych elementów strony.
        </li>
      </ul>
      <h2 className="bold">Informacje na temat procedury</h2>
      <p>
        Każdy ma prawo wystąpić z żądaniem zapewnienia dostępności cyfrowej tej
        strony internetowej lub jej elementów.
      </p>
      <p>Zgłaszając takie żądanie podaj:</p>
      <ul>
        <li>swoje imię i nazwisko,</li>
        <li>swoje dane kontaktowe (np. numer telefonu, e-mail),</li>
        <li>
          dokładny adres strony internetowej, na której jest niedostępny cyfrowo
          element lub treść,
        </li>
        <li>
          opis na czym polega problem i jaki sposób jego rozwiązania byłby dla
          Ciebie najwygodniejszy.
        </li>
      </ul>
      <p>
        {" "}
        Na Twoje zgłoszenie odpowiemy najszybciej jak to możliwe, nie później
        niż w ciągu 14 dni od jego otrzymania. Jeżeli ten termin będzie dla nas
        zbyt krótki poinformujemy Cię o tym. W tej informacji podamy nowy
        termin, do którego poprawimy zgłoszone przez Ciebie błędy lub
        przygotujemy informacje w alternatywny sposób. Ten nowy termin nie
        będzie dłuższy niż 2 miesiące.
      </p>
      <p>
        Jeżeli nie będziemy w stanie zapewnić dostępności cyfrowej strony
        internetowej lub treści, wskazanej w Twoim żądaniu, zaproponujemy Ci
        dostęp do nich w alternatywny sposób.
      </p>
      <h2 className="bold">
        Obsługa wniosków i skarg związanych z dostępnością
      </h2>
      <p>
        Uprzejmie informujemy, że zgodnie z ustawą z dnia 4 kwietnia 2019 r. o
        dostępności cyfrowej stron internetowych i aplikacji mobilnych podmiotów
        publicznych każdy ma prawo wystąpić do podmiotu publicznego z żądaniem
        udostępnienia cyfrowego wskazanej strony internetowej, aplikacji
        mobilnej lub ich elementów, ewentualnie zapewnienia dostępu
        alternatywnego, na warunkach określonych w ustawie. W przypadku odmowy
        wnoszący żądanie możne złożyć skargę z zastosowaniem przepisów ustawy z
        dnia 14 czerwca 1960 r. Kodeks postępowania administracyjnego, a także
        powiadomić Rzecznika Praw Obywatelskich: <a href="https://www.rpo.gov.pl">www.rpo.gov.pl</a>
      </p>
      <h2 className="bold">Dostępność architektoniczna</h2>
      <h3 className="bold"> Dostępność architektoniczna siedziby podmiotu: </h3>
      <p>
        Zespół Szkolno - Przedszkolny w Nidku częściowo jest dostosowany dla
        osób niepełnosprawnych. Posiada: podjazd przy wejściu głównym do
        budynku, platformę dla niepełnosprawnych przy wejściu do hali sportowej,
        szerokie drzwi, toalety dla niepełnosprawnych, miejsce parkingowe dla
        niepełnosprawnych.
      </p>
      <h3 className="bold">Dostępność parkingu:</h3>
      <p>Budynek posiada parking.</p>
      <h3 className="bold">Dostępność toalet:</h3>
      <p>
        W budynku dostępne są toalety dla interesantów oraz toalety dla
        niepełnosprawnych.
      </p>
      <h3 className="bold">Dostępność komunikacyjno-informacyjna:</h3>
      <p>
        W ZSP wszystkie dostępne dla interesantów pomieszczenia oznaczone są
        tabliczkami z napisami w j. polskim i opisane alfabetem Braille'a.
        Szkoła umożliwia osobie niedowidzącej lub niewidomej poruszanie się na
        jej terenie z pracownikiem obsługi lub z psem przewodnikiem. Szkoła
        posiada pętlę indukcyjną dostępną w sekretariacie.
      </p>
      <a
        href="../../../documents/Dostepnosc_cyfrowa.pdf"
        download="deklaracja_dostepnosci.pdf"
        data-download
      >
        Deklaracja dostępności (66KB | pdf)
      </a>
    </div>
  )
}

export default AccessibilityDeclarationTemplate
